import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const getSellingPlanGroups = async (bustCache: boolean) => {
  const sellingPlanGroupsRes = await typedFrontendVendorApi.getReq("/selling-plan-group", {
    query: {
      cache: bustCache ? "false" : "true",
    },
  });

  if (sellingPlanGroupsRes.type === "error") {
    return;
  }

  return sellingPlanGroupsRes.body;
};
