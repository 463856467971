import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";

export const getChangeSubscriptionStatusLabel = (
  purchaseStates: IPurchaseStateWithCustomerRelationship[],
  selectedResources: string[]
) => {
  if (
    purchaseStates.findIndex(ps => selectedResources.includes(ps.id) && ps.purchaseStateStatus === "PAUSED") != -1
  ) {
    return "Activate";
  }
  return "Pause";
};

//picks only purchase states with status, if at least one "PAUSED" sub is picked it will choose only "PAUSED" subs
//otherwise it will choose every "ACTIVE"
//"its better to activate all the subs than to pause them"
export const getChangeSubscriptionStatusAction = (
  purchaseStates: IPurchaseStateWithCustomerRelationship[],
  selectedResources: string[],
  onPausedAction: () => void,
  onActivatedAction: () => void
) => {
  if (
    purchaseStates.findIndex(ps => selectedResources.includes(ps.id) && ps.purchaseStateStatus === "PAUSED") != -1
  ) {
    return onPausedAction();
  }
  return onActivatedAction();
};
